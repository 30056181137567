<template>
    <div id="community">
        <router-view></router-view>
        <nut-drag direction="y" :style="{ right: '0px', bottom: '100px' }">
            <mall-fixed-nav-v2 @selected="selected" v-if="path != 'cart' && path != ''"></mall-fixed-nav-v2>
        </nut-drag>
    </div>
</template>
<script>
  import MallFixedNavV2 from '@/components/nav/mall-fixed-nav-v2'

  export default {
    components: {MallFixedNavV2},
    data () {
      return {
        showfab: false,
        navList: [
          {
            id: 1,
            text: '购物车',
            icon: 'https://img14.360buyimg.com/imagetools/jfs/t1/130725/4/3157/1704/5ef83e95Eb976644f/b36c6cfc1cc1a99d.png',
          },
        ],
        path: ''
      }
    },
    created () {
      document.title = '社区商城'
      let path = this.$route.query.path

      // console.log('this.common_platform.params',this.common_platform.params)

      if(path){
        this.path = path
      }else{
        this.path = ''
      }
      let query = ''
      if (this.$route.query.query != 'undefined') {
        query = this.$route.query.query
      }

      if (!this.$utils.rule.empty(query)) {
        query = JSON.parse(decodeURIComponent(query))
      }
      if (path) {
        this.$router.replace({path: 'community/' + path, query: query})
      }
    },
    methods: {
      selected () {
        this.showfab = false
        let routeName = this.$route.name
        if (['Cart', 'OrderConfrim'].includes(routeName)) {
          return
        }
        this.$utils.debounce(
          () => {
            if (this.$platform.wxsdk.isWechat()) {
              this.$platform.wxsdk.wxRoute({
                type: 'navigateTo',
                url: '/web/community/cart',
              })
            } else {
              this.$router.push({path: 'cart'})
            }
          },
          1000,
          false
        )
      },
    },
  }
</script>
<style lang="scss" scoped>
    #community {
        position: relative;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding-bottom: constant(safe-area-inset-bottom);
        padding-bottom: env(safe-area-inset-bottom);
    }
</style>
