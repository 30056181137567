<template>
    <div class="nut-fixednav">
        <div class="fixed-btn" @click="selected">
            <div class="cart" >
                <div class="img">
                    <img src="https://api.mengjingloulan.com/storage/wechat/common/cart.png"/>
                </div>
                <div class="text">购物车</div>
            </div>

        </div>
    </div>
</template>
<script>
  export default {
    name: 'mall-fixed-nav-v2',
    props: {
      active: {
        type: Boolean,
        default: false
      },
      navList: {
        default: () => [],
        type: Array
      },
      activeText: {
        default: '收起导航',
        type: String
      },
      unActiveText: {
        default: '快速导航',
        type: String
      },
      type: {
        default: 'right',
        type: String
      }
    },
    computed: {
      styled () {
        return {
          active: this.defaultActive,
          [this.defaultType]: true
        }
      }
    },
    watch: {
      active (newV, oldV) {
        this.defaultActive = newV
      },
      defaultActive (newV, oldV) {
        this.$emit('update:active', newV)
      }
    },
    data () {
      return {
        defaultActive: false,
        defaultType: ''
      }
    },
    mounted () {
      this.defaultActive = this.active
      this.defaultType = this.type
    },
    methods: {
      selected (item, $event) {
        this.$emit('selected')
      }
    }
  }
</script>

<style lang="scss" scoped>
    .nut-fixednav {
        height: 50px;
        display: flex;
        align-items: center;
        position: relative;
        z-index: 0;
        width: 0px;

        /*&.active {*/
        /*&.left {*/
        /*.fixed-btn img {*/
        /*transform: rotate(180deg);*/
        /*}*/
        /*.fixed-list {*/
        /*left: 0;*/
        /*}*/
        /*}*/
        /*.fixed-btn {*/
        /*img {*/
        /*transform: rotate(0deg);*/
        /*}*/
        /*}*/
        /*.fixed-list {*/
        /*right: 0;*/
        /*}*/
        /*}*/

        .fixed-btn {
            box-sizing: border-box;
            position: absolute;
            right: 0;
            z-index: 2;
            width: 50px;
            padding-left: 5px;
            height: 50px;
            background: #f46a17;
            border-radius: 25px 0px 0px 25px;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
            display: flex;
            align-items: center;
            justify-content: center;
            > img {
                margin-right: 5px;
                width: 4px;
                height: 10px;
                flex-shrink: 0;
                transition: all 0.3s;
                transform: rotate(0)
            }
            > span {
                width: 24px;
                line-height: 13px;
                font-size: 10px;
                color: #fff;
                flex-shrink: 0;
            }
        }
        ul.fixed-list {
            position: absolute;
            right: -100%;
            transition: all 0.5s;
            z-index: 1;
            flex-shrink: 0;
            height: 44px;
            background: #fff;
            display: flex;
            justify-content: space-between;
            border-radius: 25px 0px 0px 25px;
            box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.2);
            padding: {
                left: 20px;
                right: 50px;
            }
            li {
                position: relative;
                flex: 1;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                min-width: 40px;
                flex-shrink: 0;
                > img {
                    width: 20px;
                    height: 20px;
                    margin-bottom: 2px;
                }
                span {
                    font-size: 10px;
                    color: #333;
                }
                b {
                    position: absolute;
                    right: 0;
                    top: 1px;
                    height: 14px;
                    line-height: 14px;
                    font-size: 10px;
                    padding: 0 3px;
                    color: #fff;
                    background: #f46a17;
                    border: 1px solid #fff;
                    border-radius: 7px;
                    text-align: center;
                    min-width: 12px;
                }
            }
        }
        &.left {
            .fixed-btn {
                flex-direction: row-reverse;
                right: auto;
                left: 0;
                border-radius: 0 25px 25px 0;

            }
            ul.fixed-list {
                left: -100%;
                right: auto;
                border-radius: 0px 25px 25px 0px;
                padding: {
                    left: 50px;
                    right: 20px;
                }
            }
        }
    }

    .cart {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        .img {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-bottom: 3px;

            img {
                width: 20px;
                height: 20px;
            }
        }
        .text {
            font-size: 9px;
            color: #fff;
        }
    }

</style>
